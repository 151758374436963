import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "flyer" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "elcyklar-från-flyer---din-ultimata-cykelupplevelse"
    }}>{`Elcyklar från Flyer - Din Ultimata Cykelupplevelse`}</h1>
    <p>{`Välkommen till vår exklusiva samling av elcyklar från `}<strong parentName="p">{`Flyer`}</strong>{`. Med ledande teknologi och schweizisk precision erbjuder Flyer elcyklar för både terräng och stadsmiljö. Utforska vårt sortiment och hitta den perfekta elcykeln för dina behov.`}</p>
    <h2 {...{
      "id": "flyer-uproc2-serien"
    }}>{`Flyer Uproc2 Serien`}</h2>
    <p><strong parentName="p">{`Flyer Uproc2`}</strong>{` är designad för äventyr i både skog och stad. Utrustad med en kraftfull Panasonic GX Ultimate Pro Fit-motor och ett stort 750 Wh batteri, levererar Uproc2 exceptionell prestanda och lång räckvidd. De hydrauliska skivbromsarna och de punkteringsskyddade Maxxis Rekon-däcken ger optimal säkerhet och komfort i alla förhållanden.`}</p>
    <p><strong parentName="p">{`Nyckelfunktioner:`}</strong></p>
    <ul>
      <li parentName="ul">{`Panasonic GX Ultimate Pro Fit-motor`}</li>
      <li parentName="ul">{`750 Wh batteri`}</li>
      <li parentName="ul">{`Shimano Deore växelsystem`}</li>
      <li parentName="ul">{`Heldämpad konstruktion för smidig körning`}</li>
    </ul>
    <h2 {...{
      "id": "flyer-goroc2-serien"
    }}>{`Flyer Goroc2 Serien`}</h2>
    <p>{`För den moderna cyklisten är `}<strong parentName="p">{`Flyer Goroc2`}</strong>{` det perfekta valet. Den robusta mittmotorn i kombination med ett 750 Wh batteri ger utmärkt prestanda överallt, från stadsträckor till lätt terräng. Serien är även utrustad med kraftfulla hydrauliska skivbromsar och 29-tumsdäck för en säker och bekväm cykeltur.`}</p>
    <p><strong parentName="p">{`Nyckelfunktioner:`}</strong></p>
    <ul>
      <li parentName="ul">{`Panasonic mittmotor`}</li>
      <li parentName="ul">{`750 Wh batteri`}</li>
      <li parentName="ul">{`Shimano Deore 10-växlar`}</li>
      <li parentName="ul">{`Suntour XCR34 Air-framgaffel`}</li>
    </ul>
    <h2 {...{
      "id": "flyer-upstreet5-serien"
    }}>{`Flyer Upstreet5 Serien`}</h2>
    <p>{`För pendling och längre turer erbjuder `}<strong parentName="p">{`Flyer Upstreet5`}</strong>{` ultimat komfort och prestanda. Med en tyst och effektiv Panasonic GX Ultimate motor, 750 Wh batteri och robust design är denna serie idealisk för daglig stadscykling och äventyr. Den ergonomiska designen och högkvalitativa komponenterna säkerställer en pålitlig och smidig körupplevelse.`}</p>
    <p><strong parentName="p">{`Nyckelfunktioner:`}</strong></p>
    <ul>
      <li parentName="ul">{`Panasonic GX Ultimate motor`}</li>
      <li parentName="ul">{`750 Wh integrerat batteri`}</li>
      <li parentName="ul">{`Shimano Deore hydrauliska skivbromsar`}</li>
      <li parentName="ul">{`Schwalbe Big Ben däck`}</li>
    </ul>
    <h2 {...{
      "id": "flyer-gotour6-serien"
    }}>{`Flyer Gotour6 Serien`}</h2>
    <p>{`För cyklister som söker lång räckvidd och hög komfort är `}<strong parentName="p">{`Flyer Gotour6`}</strong>{` ett oslagbart val. Utrustad med Bosch Performance Line mittmotor och ett 625 Wh PowerTube batteri, erbjuder Gotour6 kraftfull och uthållig prestanda. Serien är också utrustad med hydrauliska skivbromsar och Schwalbe Energizer Plus-däck för optimal säkerhet och komfort.`}</p>
    <p><strong parentName="p">{`Nyckelfunktioner:`}</strong></p>
    <ul>
      <li parentName="ul">{`Bosch Performance Line mittmotor`}</li>
      <li parentName="ul">{`625 Wh PowerTube batteri`}</li>
      <li parentName="ul">{`Schwalbe Energizer Plus däck`}</li>
      <li parentName="ul">{`Hydrauliska skivbromsar`}</li>
    </ul>
    <h2 {...{
      "id": "köpguide-vilken-flyer-elcykel-är-rätt-för-dig"
    }}>{`Köpguide: Vilken Flyer elcykel är rätt för dig?`}</h2>
    <ol>
      <li parentName="ol"><strong parentName="li">{`För Terräng och Äventyr:`}</strong>{` Välj `}<strong parentName="li">{`Flyer Uproc2`}</strong>{` för dess kraftfulla motor och robusta design.`}</li>
      <li parentName="ol"><strong parentName="li">{`För Stadscykling och Pendling:`}</strong>{` `}<strong parentName="li">{`Flyer Goroc2`}</strong>{` erbjuder en perfekt balans mellan kraft och komfort.`}</li>
      <li parentName="ol"><strong parentName="li">{`För Kombinerade Behov:`}</strong>{` `}<strong parentName="li">{`Flyer Upstreet5`}</strong>{` är idealisk med dess premiumfunktioner och ergonomiska design.`}</li>
      <li parentName="ol"><strong parentName="li">{`För Långa Turer och Bekvämlighet:`}</strong>{` `}<strong parentName="li">{`Flyer Gotour6`}</strong>{` med Bosch-motorn ger dig lång räckvidd och maximal komfort.`}</li>
    </ol>
    <p>{`Utforska vårt sortiment och upplev nästa nivå av cykling med Flyer elcyklar. Vare sig du är ute efter en elmountainbike eller en pålitlig stads- och pendelcykel, har vi det perfekta alternativet för dig. Köp din `}<strong parentName="p">{`Flyer elcykel`}</strong>{` online och upptäck friheten med överlägsen teknik och design.`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      